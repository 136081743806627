<template>
  <div class="uppost" ref="upPage">
    <Nav></Nav>
    <div class="uppost-con">
      <div class="uppost-title"><span @click="onGoIndex">首页</span>>>上传视频</div>
      <div class="uppost-detail">
        <div class="small-pic-preview">
          <img v-for="pic in picList" :src="pic.url" :key="'pic_small_' + pic.key" />
        </div>
        <!-- <div class="add-uuid">
          <el-button @click="onAddPic()">添加图片</el-button>
          <el-button @click="onUsePicFileAsPicText()"
            >以文件名作为图片说明</el-button
          >
        </div> -->
        <div class="up-con">
          <div class="up-left">
            <div>
              <span class="title">新闻标题：</span>
              <el-input v-model="post.title" placeholder ref="postTitle"></el-input>
            </div>
            <div>
              <span class="title">目标稿库：</span>
              <el-radio-group v-model="post.targetStore" class="content">
                <el-radio v-for="upStore in canUploadStore" :key="'store_' + upStore.id" class="radio" :label="upStore.id">{{ upStore.name }}</el-radio>
              </el-radio-group>
            </div>
            <div>
              <span class="title">发生时间：</span>
              <el-date-picker v-model="post.eventTime" type="date" value-format="yyyy-MM-dd" :default-value="new Date()" placeholder="选择日期"></el-date-picker>
            </div>
            <div>
              <span class="title">发生地点：</span>
              <el-cascader class="content" :options="$rtm.store.addrTree" :props="types_picker_props" filterable clearable separator=" > " v-model="addr_picker_arr"></el-cascader>
            </div>
            <div>
              <span class="title">稿件类型1：</span>
              <el-cascader class="content" :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr1"></el-cascader>
            </div>
            <div>
              <span class="title">稿件类型2：</span>
              <el-cascader class="content" :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr2"></el-cascader>
            </div>
            <div>
              <span class="title">稿件类型3：</span>
              <el-cascader class="content" :options="$rtm.store.typeTree" :props="types_picker_props" filterable clearable separator=">" v-model="type_picker_arr3"></el-cascader>
            </div>
            <div>
              <span class="title">署名：</span>
              <el-input v-model="post.author" placeholder></el-input>
            </div>
            <div>
              参与投稿活动：
              <div>
                <el-checkbox-group class="activity" v-model="ftIdCheck">
                  <el-checkbox v-for="k in atopics" :key="k.id" :label="k.id">
                    {{ k.shortTitle }}
                  </el-checkbox>
                </el-checkbox-group>
              </div>
            </div>
          </div>
          <div class="up-right">
            <div>
              稿件正文：
              <el-input v-model="post.mainText" type="textarea" :rows="7" ref="postMainText"></el-input>
            </div>
            <div>
              备注：
              <el-input v-model="post.note" type="textarea" :rows="4" ref="postNote"></el-input>
            </div>
            <el-button type="primary" class="save" @click="onSavePost()">保存</el-button>
          </div>
        </div>
        <div class="add-uuid">
          <div class="add-video">
            <div class="top" v-loading="uploading">
              <el-upload
                class="avatar-uploader"
                :action="videoUrl"
                :show-file-list="false"
                :on-change="onVideoSelected"
                :on-progress="onUploadProgress"
                :on-success="onGetVideoURL"
                :with-credentials="true"
                accept=".mp4, .avi, .MP4, .AVI"
              >
                <el-button>添加视频</el-button>
              </el-upload>

              <div class="video-progress">
                <div class="progress-bar" :class="{ finish: videoProgress == 100.0 }" :style="{ width: videoProgress + '%' }"></div>
                <div class="progress-title" v-if="videoProgress != 100.0">视频上传进度：{{ videoProgress }}%</div>
                <div class="progress-title" v-if="videoProgress == 100.0">视频上传完毕</div>
              </div>
            </div>
            <div class="show">
              <video :src="videoImg" controls width="98%" height="400"></video>
            </div>
          </div>
          <div class="add-poster">
            <div class="top">
              <el-upload
                class="avatar-uploader"
                :action="postImage"
                :show-file-list="false"
                :on-progress="onUploadPicProgress"
                :on-success="onGetPosterURL"
                :with-credentials="true"
                accept=".jpg, .jpeg, .JPG, .JPEG,.png"
              >
                <el-button>添加封面</el-button>
              </el-upload>

              <div class="pic-progress">
                <div class="progress-bar" :class="{ finish: videoPosterProgress == 100.0 }" :style="{ width: videoPosterProgress + '%' }"></div>
                <div class="progress-title" v-if="videoPosterProgress != 100.0">图片上传进度：{{ videoPosterProgress }}%</div>
                <div class="progress-title" v-if="videoPosterProgress == 100.0">图片上传完毕</div>
              </div>
            </div>
            <div class="show">
              <img v-if="imgURL" :src="imgURL" class="avatar" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <Bottom></Bottom>
  </div>
</template>

<script>
import RD from '@/api/RD'
import Nav from '@/components/nav.vue'
import Bottom from '@/components/bottom.vue'

export default {
  components: { Nav, Bottom },
  data: function () {
    return {
      uploading: false,
      post: {
        title: '',
        author: '',
        checked: false,
        mainText: '',
        note: '',
        type: [],
        eventTime: new Date().getFullYear() + '-' + ('' + (new Date().getMonth() + 1)).padStart(2, '0') + '-' + ('' + new Date().getDate()).padStart(2, '0'),
        targetStore: 0,
      },
      types_picker_props: {
        value: 'id',
        label: 'name',
        children: 'subs',
        checkStrictly: true,
      },
      type_picker_arr1: [],
      type_picker_arr2: [],
      type_picker_arr3: [],
      addr_picker_arr: [],
      picList: [],
      atopics: [],
      postImage: '', // 视频封面
      imgURL: '', // 本地图片路径
      videoImg: '', // 视频的显示
      videoUrl: '', // 本地视频路径显示
      posterUUID: '', // 上传后的UUID
      videoUUID: '', // 上传后的视频uuid
      unUsePoster: false, // 封面不合格
      canUploadStore: [], // 可以上传的视频库
      videoProgress: 0, // 视频上传进度条
      videoPosterProgress: 0, // 视频封面上传进度条
      atopics: [],
      ftIdCheck: [],
    }
  },
  created() {
    const thiz = this
    this.reload()
    RD.pure()
      .user()
      .sync()
      .then((data) => {
        this.post.targetStore = data.upVideoStores[0].id
        this.post.author = data.chnName
        this.canUploadStore = data.upVideoStores
        if (data.upVideoStores.length != 0) {
          var newarr = []
          data.upVideoStores.forEach((k) => {
            newarr.push(k.id)
          })
          if (newarr.indexOf(65536) != -1) {
            this.post.targetStore = 65536
          } else {
            this.post.targetStore = data.upVideoStores[0].id
          }
        } else {
          thiz.$message('当前账户没有上传视频的库')
        }
      })
      .catch(() => {
        this.$router.push('/index.html')
      })
    RD.activityTopic()
      .forUpload()
      .then((topics) => {
        // ftIdCheck
        var ftId = parseInt(this.$route.query.ftId)
        topics.forEach((topic) => {
          if (topic.id == ftId) {
            thiz.ftIdCheck.push(ftId)
          }
        })
        console.log('opp', thiz.ftIdCheck)
        thiz.atopics = topics
      })
    thiz.postImage = RD.myPost().upPosterFile_URL()
    thiz.videoUrl = RD.myPost().upVideoFile_URL()
  },
  mounted() {
    this.$refs.postTitle.$refs.input.ondrop = function (e) {
      e.stopPropagation()
    }
    this.$refs.postMainText.$refs.textarea.ondrop = function (e) {
      e.stopPropagation()
    }
    this.$refs.postNote.$refs.textarea.ondrop = function (e) {
      e.stopPropagation()
    }
  },
  methods: {
    reload() {
      const thiz = this
      // var arrs = [2, 4, 3];
      // var target = [];
      // var duplicated = false;
      // for (var i = 0; i < arrs.length; i++) {
      //   var targetNumber = arrs[i];
      //   if (target.indexOf(targetNumber) != -1) {
      //     // console.log("duplicated", targetNumber);
      //     duplicated = true;
      //     break;
      //   } else {
      //     target.push(targetNumber);
      //   }
      // }
      // console.log("duplicated", duplicated);

      // var arrs = [3, 2, 3];
      // var newArr = [];

      // arrs.forEach(i => {
      //   if (newArr.indexOf(i) == -1) {
      //     newArr.push(i);
      //   }
      // });

      // console.log("mx", newArr);

      if (this.$rtm.store.typeTree.length == 0) {
        RD.type()
          .tree()
          .then((data) => {
            this.$rtm.store.typeTree = data
          })
      }
      if (this.$rtm.store.addrTree.length == 0) {
        RD.addr()
          .tree()
          .then((data) => {
            this.$rtm.store.addrTree = data
          })
      }
      thiz.type_picker_arr = thiz.treeList
    },
    onSavePost() {
      var thiz = this
      if (this.postImage == '') {
        this.$message({
          type: 'warning',
          message: '没有上传图片！',
        })
        return
      }
      if (this.post.title.length == 0) {
        this.$message({
          type: 'warning',
          message: '标题不能为空',
        })
        return
      }
      if (this.post.targetStore == 0) {
        this.$message({
          type: 'warning',
          message: '请选择输出稿库',
        })
        return
      }
      if (this.addr_picker_arr.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选则发生地点',
        })
        return
      }
      if (this.addr_picker_arr.length == 1) {
        this.$message({
          type: 'warning',
          message: '请选择二级地点',
        })
        return
      }
      if (this.type_picker_arr1.length == 0 && this.type_picker_arr2.length == 0 && this.type_picker_arr3.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择至少一个类型',
        })
        return
      }

      if (this.post.author.length == 0) {
        this.$message({
          type: 'warning',
          message: '请输入署名',
        })
        return
      }
      if (this.post.eventTime.length == 0) {
        this.$message({
          type: 'warning',
          message: '请选择发生时间',
        })
        return
      }
      if (this.post.mainText.length == 0) {
        this.$message({
          type: 'warning',
          message: '请输入正文',
        })
        return
      }
      if (thiz.posterUUID == '') {
        if (parseInt(this.videoPosterProgress) != 0) {
          this.$message({
            type: 'warning',
            message: '请等待封面上传完毕',
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请先上传封面',
          })
        }
        return
      }

      if (thiz.unUsePoster == false) {
        this.$message({
          type: 'warning',
          message: '封面大小不合适，请重新上传封面',
        })
        return
      }

      if (thiz.videoUUID == '') {
        if (parseInt(this.videoProgress) != 0) {
          this.$message({
            type: 'warning',
            message: '请等待视频上传完毕',
          })
        } else {
          this.$message({
            type: 'warning',
            message: '请先上传视频',
          })
        }

        return
      }
      var post = {}
      post.storeId = this.post.targetStore
      post.title = this.post.title
      post.author = this.post.author
      post.mainText = this.post.mainText
      post.note = this.post.note
      post.eventTime = this.post.eventTime
      post.eventAddrId = this.addr_picker_arr[this.addr_picker_arr.length - 1]
      post.typeIds = []
      post.activityTopicIds = this.ftIdCheck
      post.posterUUID = thiz.posterUUID
      post.videoUUID = thiz.videoUUID

      if (this.type_picker_arr1.length != 0) {
        post.typeIds.push(this.type_picker_arr1[this.type_picker_arr1.length - 1])
      }
      if (this.type_picker_arr2.length != 0) {
        post.typeIds.push(this.type_picker_arr2[this.type_picker_arr2.length - 1])
      }
      if (this.type_picker_arr3.length != 0) {
        post.typeIds.push(this.type_picker_arr3[this.type_picker_arr3.length - 1])
      }

      var arrs = []
      post.typeIds.forEach((k) => {
        if (arrs.indexOf(k) == -1) {
          arrs.push(k)
        }
      })

      post.typeIds = arrs

      // console.log('upvideopost', post)

      RD.myPost()
        .upVideoPost(post)
        .then(() => {
          thiz.$router.push('/myPost.html')
        })
    },
    // 上传海报成功后的处理
    onGetPosterURL(res, file) {
      console.log('res', res)
      var thiz = this
      if (res.code == 0) {
        thiz.unUsePoster = true
      } else {
        thiz.$message(res.msg)
        thiz.unUsePoster = false
      }
      thiz.imgURL = URL.createObjectURL(file.raw)
      thiz.posterUUID = res.data
    },
    onBeforePosterUpload(file) {
      var fileName = file.name
      var fileExtName = fileName.substring(fileName.lastIndexOf('.')).toLowerCase()
      console.log('fileExtName', fileExtName)
      if (!fileExtName.match(/.jpg|.jpeg/)) {
        this.$rtm.message.alert('文件格式必须为：jpg/jpeg', '格式错误')
        return false
      }
      thiz.imgURL = URL.createObjectURL(file.raw)
      thiz.posterUUID = res.data
    },
    onVideoSelected(file) {
      this.videoImg = URL.createObjectURL(file.raw)
    },
    onGetVideoURL(res, file) {
      this.uploading = false
      console.log('onGetVideoURL file', file)
      console.log('res23', res)
      if (res.code != 0) {
        this.$msgbox({ message: res.msg, type: 'warning' })
        this.videoUUID = ''
        this.videoProgress = 0
        return
      }
      var thiz = this
      thiz.videoUUID = res.data
    },
    // 上传的进程
    onUploadProgress(event) {
      var thiz = this
      this.uploading = true
      thiz.videoProgress = parseFloat(event.percent).toFixed(2)
    },
    // videoPostProgress
    onUploadPicProgress(event) {
      var thiz = this
      thiz.videoPosterProgress = parseFloat(event.percent).toFixed(2)
    },
    onGoIndex() {
      this.$router.push('/index.html')
    },
  },
}
</script>
<style lang="less">
.el-radio__input .el-radio__inner {
  border: 1px solid #cf0000 !important;
}
</style>

<style lang="less" scoped>
@media all and (max-width: 800px) {
  .uppost-con {
    width: 750px;
    margin: 0 auto;
    background-color: #ccc;
    .uppost-title {
      height: 60px;
      width: 100%;
      text-align: left;
      line-height: 60px;
      font-size: 14px;
      margin-left: 5px;
      span {
        cursor: pointer;
        font-size: 1.3rem;
      }
      span:hover {
        color: red;
      }
    }
    .uppost-detail {
      width: 100%;
      .small-pic-preview {
        width: 100%;
        overflow: hidden;
        text-align: left;
        background-color: #ccc;
        img {
          height: 100px;
          display: inline-block;
          margin: 10px;
        }
      }
      .pic-list {
        width: 100%;
        overflow: hidden;
        background-color: #ccc;
        .pic-item {
          position: relative;
          height: 150px;
          width: 96%;
          margin: 10px 2%;
          top: 0px;
          display: flex;
          .pic-image {
            width: 320px;
            height: 150px;
            background: no-repeat 50% / contain;
            position: relative;
            overflow: hidden;
            i {
              display: block;
              line-height: 40px;
              font-size: 25px;
              background-color: rgba(48, 38, 38, 0.4);
              color: rgb(226, 223, 223);
              border-radius: 20px;
              overflow: hidden;
              height: 40px;
              width: 40px;
              right: -50px;
            }
            .up {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 5px;
            }
            .close {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 55px;
            }
            .down {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 105px;
            }
          }
          .pic-image:hover {
            i {
              right: 50px;
            }
          }
          .pic-info {
            flex: 1;
            height: 150px;
            display: flex;
            flex-direction: column;
            .status-line {
              height: 30px;
            }
            .text {
              flex: 1;
            }
          }
        }
      }
      .up-con {
        border: 1px solid #f3e8c8;
        background-color: #fffeef;
        padding: 15px 30px;
        line-height: 50px;
        display: flex;
        font-size: 1.3rem;
        text-align: left;

        .up-left {
          width: 50%;
          .el-input__inner,
          .el-textarea__inner {
            border: 1px solid rgb(14, 3, 3) !important;
            font-family: '宋体' !important;
          }
          .title {
            display: inline-block;
            width: 100px;
            font-size: 14px;
          }
          .content {
            margin: 5px 0px;
            width: 70%;
            .radio {
              display: inline-bloc;
              height: 30px;
            }
          }
          div {
            .el-input {
              width: 70%;
            }
          }
        }
        .up-right {
          width: 50%;
          .save {
            margin-top: 30px;
            margin-left: 40%;
          }
        }
      }
      .add-uuid {
        height: auto;
        background: #ccc;
        margin: 10px;
        text-align: left;
        display: flex;
        .add-video {
          width: 50%;
          height: auto;
          margin-bottom: 10px;
          .top {
            text-align: center;
            .video-progress {
              margin-top: 10px;
              height: 30px;
              position: relative;
              background-color: #ff7918;
              .progress-bar {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                background-color: red;
              }
              .progress-bar.finish {
                background-color: green;
              }
              .progress-title {
                height: 30px;
                position: relative;
                line-height: 30px;
                color: white;
              }
            }
          }
          .show {
            height: 400px;
            margin-top: 10px;
            video {
              background-color: black;
              width: 100%;
            }
          }
        }
        .add-poster {
          width: 48%;
          margin-left: 20px;
          height: auto;
          margin-bottom: 10px;
          .top {
            text-align: center;
            height: 70px;
            .el-button {
              height: 40px;
            }
            .pic-progress {
              margin-top: 10px;
              height: 30px;
              position: relative;
              background-color: #ff7918;
              .progress-bar {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                background-color: red;
              }
              .progress-bar.finish {
                background-color: green;
              }
              .progress-title {
                height: 30px;
                position: relative;
                line-height: 30px;
                color: white;
              }
            }
          }
          .show {
            height: 400px;
            margin-top: 18px;
            border: 1px solid #000;
            img {
              width: 100%;
              height: 100%;
              background-size: cover;
            }
          }
        }
      }
    }
  }
}
@media all and (min-width: 801px) {
  .uppost-con {
    width: 120rem;
    margin: 0 auto;
    background-color: #ccc;
    .uppost-title {
      height: 6rem;
      width: 100%;
      text-align: left;
      line-height: 6rem;
      font-size: 1.4rem;
      margin-left: 5px;
      span {
        cursor: pointer;
        font-size: 1.4rem;
      }
      span:hover {
        color: red;
      }
    }
    .uppost-detail {
      width: 100%;
      .small-pic-preview {
        width: 100%;
        overflow: hidden;
        text-align: left;
        background-color: #ccc;
        img {
          height: 100px;
          display: inline-block;
          margin: 10px;
        }
      }
      .pic-list {
        width: 100%;
        overflow: hidden;
        background-color: #ccc;
        .pic-item {
          position: relative;
          height: 15rem;
          width: 96%;
          margin: 10px 2%;
          top: 0px;
          display: flex;
          .pic-image {
            width: 32rem;
            height: 15rem;
            background: no-repeat 50% / contain;
            position: relative;
            overflow: hidden;
            i {
              display: block;
              line-height: 40px;
              font-size: 25px;
              background-color: rgba(48, 38, 38, 0.4);
              color: rgb(226, 223, 223);
              border-radius: 20px;
              overflow: hidden;
              height: 40px;
              width: 40px;
              right: -50px;
            }
            .up {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 5px;
            }
            .close {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 55px;
            }
            .down {
              position: absolute;
              transition: all 200ms ease-in-out;
              top: 105px;
            }
          }
          .pic-image:hover {
            i {
              right: 50px;
            }
          }
          .pic-info {
            flex: 1;
            height: 150px;
            display: flex;
            flex-direction: column;
            .status-line {
              height: 30px;
            }
            .text {
              flex: 1;
            }
          }
        }
      }
      .up-con {
        border: 1px solid #f3e8c8;
        background-color: #fffeef;
        padding: 15px 30px;
        line-height: 50px;
        display: flex;
        font-size: 1.3rem;
        text-align: left;

        .up-left {
          width: 50%;
          .el-input__inner,
          .el-textarea__inner {
            border: 1px solid rgb(14, 3, 3) !important;
            font-family: '宋体' !important;
          }
          .title {
            display: inline-block;
            width: 100px;
          }
          .content {
            margin: 5px 0px;
            width: 70%;
            .radio {
              display: inline-bloc;
              height: 30px;
            }
          }
          div {
            .el-input {
              width: 70%;
            }
          }
        }
        .up-right {
          width: 50%;
          .save {
            margin-top: 30px;
            margin-left: 40%;
            width: 15rem;
          }
        }
      }
      .add-uuid {
        height: auto;
        background: #ccc;
        margin: 10px;
        text-align: left;
        display: flex;
        .add-video {
          width: 50%;
          height: auto;
          margin-bottom: 10px;
          .top {
            text-align: center;
            .video-progress {
              margin-top: 10px;
              height: 30px;
              position: relative;
              background-color: #ff7918;
              .progress-bar {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                background-color: red;
              }
              .progress-bar.finish {
                background-color: green;
              }
              .progress-title {
                height: 30px;
                position: relative;
                line-height: 30px;
                color: white;
              }
            }
          }
          .show {
            height: 400px;
            margin-top: 10px;
            video {
              background-color: black;
              width: 100%;
            }
          }
        }
        .add-poster {
          width: 48%;
          margin-left: 20px;
          height: auto;
          margin-bottom: 10px;
          .top {
            text-align: center;
            height: 70px;
            .el-button {
              height: 40px;
            }
            .pic-progress {
              margin-top: 10px;
              height: 30px;
              position: relative;
              background-color: #ff7918;
              .progress-bar {
                position: absolute;
                left: 0px;
                top: 0px;
                height: 100%;
                background-color: red;
              }
              .progress-bar.finish {
                background-color: green;
              }
              .progress-title {
                height: 30px;
                position: relative;
                line-height: 30px;
                color: white;
              }
            }
          }
          .show {
            height: 400px;
            margin-top: 18px;
            border: 1px solid #000;
            img {
              width: 100%;
              height: 100%;
              background-size: cover;
            }
          }
        }
      }
    }
  }
}
</style>
